<template>
  <div>
    <h1 class="heading-txt mb-3">Change Password</h1>
    <div class="row">
      <div class="col-md-5">
        <form role="form" @submit.prevent="initiateUpdate">
          <div class="card siteCard mt-3">
            <div class="p-3">
              <div class="form-group">
                <label class="text-dark" to="current_password"
                  >Current password</label
                >
                <input
                  type="password"
                  required
                  name="current_password"
                  tabindex="1"
                  v-model="password.current_password"
                  id="current_password"
                  class="form-control"
                />
                <div class="mt-2" v-if="errors.current_password">
                  <vs-alert
                    class="mb-1"
                    color="danger"
                    v-for="(error, index) in errors.current_password"
                    :key="index"
                  >
                    <span>{{ error }}</span>
                  </vs-alert>
                </div>
              </div>
              <div class="form-group">
                <label class="text-dark" to="password">New password</label>
                <input
                  type="password"
                  name="password"
                  tabindex="1"
                  required
                  v-model="password.password"
                  id="password"
                  class="form-control"
                />
                <div class="mt-2" v-if="errors.password">
                  <vs-alert
                    class="mb-1"
                    color="danger"
                    v-for="(error, index) in errors.password"
                    :key="index"
                  >
                    <span>{{ error }}</span>
                  </vs-alert>
                </div>
              </div>
              <div class="form-group">
                <label class="text-dark" to="password_confirm"
                  >Repeat new password</label
                >
                <input
                  type="password"
                  name="password_confirm"
                  required
                  v-model="password.password_confirmation"
                  tabindex="1"
                  id="password_confirm"
                  class="form-control"
                />
                <div class="mt-2" v-if="errors.password_confirmation">
                  <vs-alert
                    class="mb-1"
                    color="danger"
                    v-for="(error, index) in errors.password_confirmation"
                    :key="index"
                  >
                    <span>{{ error }}</span>
                  </vs-alert>
                </div>
              </div>
              <button
                type="submit"
                :disabled="loading"
                class="btn btn-primary btn-site px-5 mt-4"
              >
                <span> Set new password</span>
                <BtnLoading v-if="loading" class="d-inline-block" height="18" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <vs-popup title="Verify OTP" :active.sync="verifyOtpModal">
      <OTPVerify @onSuccess="verifySuccess" @onError="verifyError" />
    </vs-popup>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";
import OTPVerify from "@/components/OTPVerify.vue";


export default {
  data() {
    return {
      loading: false,
      errors: {},
      verifyOtpModal: false,
      password: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
    };
  },

  components: {
    BtnLoading,
    OTPVerify
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        location.href = "/";
      });
    },
    updatePassword() {
      if (this.password.password !== this.password.password_confirmation) {
        this.$toast.error("Passwords do not match");
      } else {
        this.loading = true;
        const data = this.password;
        let fetch = {
          path: `/profile/update/password`,
          data,
        };

        this.$store
          .dispatch("postRequest", fetch)
          .then((resp) => {
            const { data } = resp.data;

            this.loading = false;

            this.$toast.success(
              "Password Update",
              "Your password has been updated successfully",
              this.$toastPosition
            );

            setTimeout(() => {
              this.logout();
            }, 1500);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response) {
              const { errors } = err.response.data;
              this.errors = errors;
              this.$toast.info(
                "Password Update",
                err.response.data.message,
                this.$toastPosition
              );
            } else {
              this.$toast.info(
                "Password Update",
                "Unable to update password, check the details and try again",
                this.$toastPosition
              );
            }
          });
      }
    },
    initiateUpdate() {
      const data = {
        email: this.user ? this.user.email : '',
      };
      let fetch = {
        path: `auth/account/resend-otp`,
        data,
      };
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
        })
        .catch((err) => { })

      this.verifyOtpModal = true;
    },
    verifySuccess() {
      this.verifyOtpModal = false;
      this.updatePassword();
    },
    verifyError(err) {
      console.log('On Error')
      if (err.response) {
        this.$toast.info(
          "OTP Verify",
          err.response.data.message,
          this.$toastPosition
        );
      } else {
        this.$toast.info(
          "OTP Verify",
          "Something went wrong, please try again",
          this.$toastPosition
        );
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.heading-txt {
  font-size: 3em;
  line-height: 1;
  font-weight: 700;
  color: #000;
}
.sect-data {
  border-bottom: 1px solid gainsboro;
  margin-bottom: 13px;
}
.active-plan {
  color: #000;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 2px;
}
</style>
