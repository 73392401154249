<template>
     <form @submit.prevent="sendVerifyOTP()">
        <div class="mb-4">

            <label class="text-sm block text-gray-700 font-medium mb-1">Enter the 4-digit code sent to you
                email</label>
            <span v-if="user" class="text-gray-500 text-sm">{{ user.email }}</span>

            <input v-model="otp" required
                class="py-3 px-3 border font-bold text-2xl rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 mt-3" />
        </div>

        <div class="mt-16">
            <button class="my-3 text-sm text-primary outline-none" type="button" @click="resendOTP">Didn't receive
                code?
                Resend</button>

            <button type="submit" :disabled="otpVerifyLoading"
                class="bg-primary w-full text-white py-3 px-4 hover:bg-primary rounded hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 relative flex justify-between">
                <span>Verify</span>
                <Loading v-if="otpVerifyLoading" size="25px" color="white" />
            </button>
        </div>
    </form>
</template>


<script>
import Loading from "@/components/Loading.vue";

export default {
    components: {
        Loading
    },
    data() {
        return {
            loading: false,
            otpVerifyLoading: false,
            otp: '',
        }
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
    },
    props: {
        newAccount: {
        type: Boolean,
        default: false,
    },
  },
    methods: {
        resendOTP() {
            this.$vs.loading();
            const data = {
                email: this.user ? this.user.email : '',
            };
            let fetch = {
                path: `auth/account/resend-otp`,
                data,
            };
            this.$store
                .dispatch("postRequest", fetch)
                .then((resp) => {
                    this.$vs.loading.close();
                    this.$toast.success(
                        "OTP Resend",
                        "OTP sent successfully",
                        this.$toastPosition
                    );
                })
                .catch((err) => {
                    this.$vs.loading.close();
                    if (err.response) {
                        this.$toast.info(
                            err.response.data.message,
                            "OTP Resend",
                            this.$toastPosition
                        );
                    } else {
                        this.$toast.info(
                            "Something went wrong, please try again",
                            "OTP Resend",
                            this.$toastPosition
                        );
                    }
                });
        },
        sendVerifyOTP() {
            this.otpVerifyLoading = true;

            let fetch = {
                path: `/auth/account/verify`,
                data: {
                    otp: this.otp,
                    email: this.user ? this.user.email : '',
                    'is_new_account': this.newAccount ? 'true' : 'false'
                },
            };

            this.$store
                .dispatch("postRequest", fetch)
                .then((resp) => {
                    this.otp = '';
                    this.$emit("onSuccess");
                })
                .catch((err) => {
                    this.$emit("onError", err)
                })
                .finally(() => {
                    this.otpVerifyLoading = false;
                });
        },
    }
}
</script>